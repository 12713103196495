<template>
  <div class="blogs">
    <div class="content-header">
      <h1>博客管理<small>分类管理</small></h1>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: '/' }">后台管理</el-breadcrumb-item>
        <el-breadcrumb-item>博客管理</el-breadcrumb-item>
        <el-breadcrumb-item>分类管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--中间内容-->
    <div class="app-container">
      <div class="box">
        <div class="filter-container">
          <el-form ref="form" :model="typeForm" label-width="80px" inline="true">
            <el-form-item label="类型名：" prop="typeName">
              <el-input v-model="typeForm.typeName" placeholder="请输入类型名"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="listTypes()">查询类型</el-button>
              <el-button type="primary" v-on:click="resetSearch()">重置</el-button>
              <el-button type="primary" class="butT" @click="handleCreate">添加类型</el-button>
            </el-form-item>
          </el-form>
        </div>
      <div class="ui container">
        <el-table size="middle" current-row-key="id" :data="dataList" stripe highlight-current-row>
          <!--                        id,用户名，真实姓名，角色，备注，最后登录时间，创建时间-->
          <el-table-column v-if="false" prop="id" min-width="15px" align="center" label="序号"></el-table-column>
          <el-table-column prop="typeName" label="分类名称" align="center"></el-table-column>
          <el-table-column label="管理" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
              <el-button size="mini" type="primary" @click="handleUpdate(scope.row)">编辑</el-button>
              <!--              <el-button size="mini" type="info" @click="handleForbidden(scope.row)">禁用</el-button>-->
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-container">
          <el-pagination
            class="pagiantion"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-sizes="[2,5,10,15]"
            :page-size="pagination.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total">
          </el-pagination>
        </div>
        <el-dialog v-bind:title="dialogTile" :visible.sync="dialogFormVisible">
          <el-form ref="operateTypeFormData" :model="operateTypeFormData" label-width="80px" inline="true" v-bind:rules="rules">
            <el-form-item label="id：" prop="typeName" v-if="false">
              <el-input v-model="operateTypeFormData.id" placeholder="请输入Id"></el-input>
            </el-form-item>
            <el-form-item label="类型名：" prop="typeName">
              <el-input v-model="operateTypeFormData.typeName" placeholder="请输入类型名"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="cancelInsertType()">取消</el-button>
            <el-button type="primary" @click="submitType()">确定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    </div>
    <br>
    <br>
  </div>
</template>

<script>
import AddTypes from '../../../components/adminTypes/AddTypes'
export default {
  data() {
    return {
      dialogTile: '',
      pagination: { // 分页相关模型数据
        page: 1, // 当前页码
        size: 10, // 每页显示的记录数
        total: 0, // 总记录数
        queryString: null // 查询条件
      },
      typeForm: {
        typeName: '',
      },
      operateTypeFormData: {  // 添加表单数据
        id: '',
        typeName: ''
      },
      dialogFormVisible: false, // 增加表单是否可见
      dialogFormVisible4Edit: false, // 编辑表单是否可见
      dataList: [], // 当前页要展示的分页列表数据
      user: {},
      nickname: '',
      // 被激活的链接地址
      avatar: '',
      rules: { // 校验规则
        // 分类名称
        typeName: [
          { required: true, message: '请输入类型名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    this.listTypes()
    // this.getUser()
  },
  methods: {
    // 分页查询
    async listTypes() {
      // 发送ajax，提交分页请求（页码，每页显示条数，查询条件)
      const param = {
        page: this.pagination.page,
        size: this.pagination.size,
        typeName: this.typeForm.typeName
      }
      const res = await this.$http.get('/type/listTypes', param)
      if (res.code !== this.$constant.RESPONSE_CODE.SUCCESS) {
        return this.$message.error('获取类型列表失败！')
      }
      this.pagination.total = res.data.total;
      this.dataList = res.data.list
    },

    resetSearch() {
      this.typeForm.typeName = ''
      this.listTypes();
    },

    /*getUser() {
      this.user = window.sessionStorage.getItem('user')
      this.nickname = JSON.parse(this.user).nickname
      this.avatar = JSON.parse(this.user).avatar
    },*/
    // 切换页码
    handleCurrentChange(currentPage) {
      // 设置最新的页码
      this.pagination.page = currentPage
      // 重新调用listTypes方法进行分页查询
      this.listTypes()
    },
    handleSizeChange(newSize) {
      this.pagination.size = newSize
      this.listTypes()
    },
    // 重置表单
    resetForm() {
      this.operateTypeFormData.id = ''
      this.operateTypeFormData.typeName = '' // 重置表格数据
      //   this.$refs.operateTypeFormData.resetFields()
    },

    // 弹出添加窗口
    handleCreate() {
      this.dialogFormVisible = true
      this.dialogTile = '新增类型'
      this.resetForm()
    },

    // 添加类型
    submitType() {
      this.$refs.operateTypeFormData.validate(async (valid) => {
        if (valid) {
          if (this.dialogTile === '新增类型') {
            const res = await this.$http.put('/type/insertType', this.operateTypeFormData);
            if (this.$constant.RESPONSE_CODE.SUCCESS === res.code) {
              this.listTypes()
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.dialogFormVisible = false;
            } else {
              this.$message.error("添加失败")
            }
          } else if (this.dialogTile === '修改类型') {
            const res = await this.$http.post('/type/updateType', this.operateTypeFormData);
            if (this.$constant.RESPONSE_CODE.SUCCESS === res.code) {
              this.listTypes()
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.dialogFormVisible = false;
              this.resetForm();
            } else {
              this.$message.error("修改失败")
            }
          }
        } else {
          this.$message.error('校验失败，请检查输入格式')
          return false
        }
      })
    },

    cancelInsertType(operateTypeFormData) {
      this.dialogFormVisible = false;
      this.resetForm();
    },
    handleDelete(rowData) {
      this.$confirm('确定删除该类型嘛？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.delete("/type/deleteType" , {id: rowData.id});
        if (res.code === this.$constant.RESPONSE_CODE.SUCCESS) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.listTypes();
        } else {
          this.$message({
            type: 'error',
            message: '删除失败'
          })
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleUpdate(rowData) {
      this.dialogFormVisible = true
      this.dialogTile = '修改类型'
      // 回显数据
      this.operateTypeFormData.typeName = rowData.typeName;
      this.operateTypeFormData.id = rowData.id;
    }
  }
}
</script>

<style lang="less" scoped>
  @import "../../../assets/css/me.css";
  @import "../../../assets/css/style.css";
</style>
